<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner title="SUPER ADMIN PROFILE" :breadcrumb="[
          {
            label: 'Super Admin',
          },
          { label: 'Super Admin Profile' },
        ]">
        </banner>

        <div class="row font" style="margin-top: -50px">
          <div class="col-xl-5 col-lg-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div class="card custom-card " style="
    
                height: 100%;
              ">
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Super Admin Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Super Admin Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1">
                    <img style="width: 40%; border-radius: 15px" :src="profileImg" alt="" />
                    <br />
                    <span style="color: #00364f">
                      <p v-if="superadmin.loginStatus == 'true'" class="text-white btn btn-sm" style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> Active</b>
                      </p>
                      <p v-else class="text-white btn btn-sm" style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12" style="font-size: 14px">
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Code : </b>
                      <span v-if="superadmin">{{ superadmin.code }} </span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Name : </b>
                      <span v-if="superadmin">{{ superadmin.name }} </span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>DOB : </b>
                      <span v-if="superadmin">{{ dateTime(superadmin.dob) }}</span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Gender : </b>
                      <span v-if="superadmin">{{ superadmin.gender }} </span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Phone Number : </b>
                      <span v-if="superadmin">+91-{{ superadmin.phone }}</span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Email Id : </b>
                      <span v-if="superadmin">{{ superadmin.email }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center">
                    <img style="width: 100%; border-radius: 15px; margin-top: -20px" :src="profileImg" alt="" />
                    <span style="color: #00364f; width: 40%">
                      <p v-if="superadmin.loginStatus == 'true'" class="text-white btn btn-sm" style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> Active</b>
                      </p>
                      <p v-else class="text-white btn btn-sm" style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="col-12" style="margin-left: 7px">
                  <div class="text">
                    <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                    <b>Address : </b>
                    <span v-if="superadmin">{{ superadmin.address }}
                      <span v-if="superadmin.state"> , {{ superadmin.state.name }} </span>
                      ,{{ superadmin.city }} {{ superadmin.pin }}
                    </span>
                  </div>
                </div>
                <!-- bussiness Detais  -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Business Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Firm Name: </b>
                      <span v-if="superadmin.orgnization">{{ superadmin.orgnization }}
                      </span>
                      <span v-else> LEGAL CSC PRIVATE LIMITED</span>
                    </div>

                    <!-- <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Business Address : </b>
                      <span v-if="superadmin">{{ superadmin.address }}
                        <span v-if="superadmin.state">
                          , {{ superadmin.state.name }}
                        </span>
                        ,{{ superadmin.city }} {{ superadmin.pin }}
                      </span>
                    </div> -->

                    <!-- <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="superadmin.state">{{ superadmin.state.name }}</span>
                    </div> -->

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>GST No. : </b>
                      <span v-if="superadmin">{{ superadmin.gstNo }}</span>
                    </div>
                  </div>
                </div>
                <!-- end  bussiness Detais  -->
                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button @click="valueAssingForProfile()" type="button" class="btn text-white btn-sm"
                      data-bs-toggle="modal" data-bs-target="#profileUpdateModel"
                      style="background-color: #00364f; margin-right: 5px">
                      <b>Edit Profile &nbsp;
                        <i class="fa fa-edit"></i>
                      </b>
                    </button>
                  </div>
                </div>

                <div class="row mt-1" style="background-color: #e3e3e3; border-radius: 15px">
                  <div class="col-12 p-1">
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>IP Address :</b>
                      <span v-if="superadmin">{{ superadmin.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Last Login :</b>
                      <span v-if="superadmin">{{ dateTimeWithTime(superadmin.last_login )}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-lg-7 col-md-7 col-12" style="padding:5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card " style="height: 100%">
              <div class="card-body">
                <div class="row" v-if="wallet.id">
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>
                  <!-- Qr Code responsive Mobile screen  -->
                  <!-- <div
                    class="d-xl-none d-md-none d-lg-none d-block col-sm-12 col-12 text-center"
                  >
                    <img style="width: 40%" :src="wallet.qr_path" alt="" />
                  </div> -->
                  <!-- end Qr Code responsive Mobile screen  -->
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Wallet Balance : </b>
                      <span>Rs.{{ wallet.balanceAmount }} </span>
                    </div>

                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Virtual Account : </b>
                      <span>{{ wallet.merchant_vpa }} </span>
                    </div>
                    <div class="text">
                      <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                      <b>Wallet Code : </b>
                      <span>{{ wallet.wallet }} </span>
                    </div>

                    <span style="color: #00364f">
                      <p v-if="wallet.status == 'true'" class="text-white btn btn-sm" style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> Active</b>
                      </p>
                      <p v-else class="text-white btn btn-sm" style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        ">
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <!-- Qr Code responsive Pc screen  -->
                  <!-- <div
                    class="col-xl-2 col-lg-2 col-md-2 d-none d-xl-block d-lg-block d-md-block"
                  >
                    <img style="width: 100%" :src="wallet.qr_path" alt="qr code" />
                  </div> -->
                  <!-- end Qr Code responsive Pc screen  -->
                </div>
                <div class="row" v-else>
                  <div class="col">
                    <button @click="formNullWallet()" type="button" class="btn text-white btn-sm" style="
                        background-color: #f21300;
                        font-size: 13px;
                        letter-spacing: 1px;
                      " data-bs-toggle="modal" data-bs-target="#walletModel">
                      <b> <font-awesome-icon icon="plus" /> Create Wallet </b>
                    </button>
                  </div>
                </div>

                <hr v-if="wallet.id" style="color: #e3e3e3" />
                <div class="row" v-if="wallet.id">
                  <div class="col-xl-3 col-md-3 col-lg-3 col-3 text-start">
                    <h4 class="d-none d-xl-block d-lg-block d-md-block"><b style="color: #00364f; font-weight: bolder">Withdrawls</b></h4>
               <b class="d-block d-xl-none d-lg-none d-md-none" style="color: #00364f; font-weight: bolder">Withdrawls</b>
                  </div>
                  <div class="col-xl-9 col-md-9 col-lg-9 col-9 text-end">
                       <b style="color: #00364f; font-weight: bolder"><button @click="walletWithDrawModelNull()" type="button" class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          " data-bs-toggle="modal" data-bs-target="#walletWithDrawModel">
                          <b> <font-awesome-icon icon="plus" /> Withdrawls </b>
                        </button></b>
                        &nbsp;
                        <router-link
                        style="
                        color: #ffff;
                            background-color: #00364f;
                            font-size: 13px;
                            letter-spacing: 1px;
                          "
                        to="/super-admin/wallet-Withdraw-Req"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small > View All </small>
                        <!-- <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        /> -->
                      </router-link>
                    
                  </div>
                 
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-1">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text" style="font-size: 14px">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">UPI</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Payment Mode</th>

                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <tr v-for="(walletWithdrawReq, index) in walletwithdrawalsReq" :key="index">
                            <td>
                              <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.amount }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.beneficiaryaccount">
                                {{ walletWithdrawReq.beneficiaryaccount.account_number }}
                              </span>

                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.merchant_vpa">
                                {{ walletWithdrawReq.merchant_vpa }}
                              </span>
                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ dateTime(walletWithdrawReq.date) }}
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.paymentMode }}
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              <p v-if="walletWithdrawReq.status == 'complete'" class="text-white btn btn-sm" style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'cancel'" class="text-white btn btn-sm" style="
                                  background-color: #f21000;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>

                              <p v-if="walletWithdrawReq.status == 'pending'" class="text-white btn btn-sm" style="
                                  background-color: #fc7f03;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'reject'" class="text-white btn btn-sm" style="
                                  background-color: black;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                            </td>
                            <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button @click="walletWithdrawReqApproved(walletWithdrawReq)"
                                  class="btn btn-sm text-white" style="
                                    padding: 5px 5px 5px 5px;
                                    background-color: #00364f;
                                  ">
                                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                </button>
                                <button @click="walletWithdrawReqCancel(walletWithdrawReq)" class="btn btn-sm text-white"
                                  style="
                                    padding: 5px 5px 5px 5px;
                                    background-color: #f21000;
                                  ">
                                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr v-if="wallet.id" style="color: #e3e3e3" />

                <!-- start list area  -->
                <div v-if="wallet.id" class="row mt-3">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Beneficiary Account</b>
                    </h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"><button @click="benificiaryNull()" type="button"
                          class="btn text-white btn-sm" style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                            box-shadow: 2px 2px 2px gray;
                          " data-bs-toggle="modal" data-bs-target="#exampleModal">
                          <b> <font-awesome-icon icon="plus" /> Bank Account </b>
                        </button></b>
                    </h4>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text" style="font-size: 14px">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Ac. Holder</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">Ifsc</th>
                          <th class="text-truncate">Account Type</th>
                          <th class="text-truncate">Bank</th>
                          <th class="text-truncate">Mobile No</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-truncate">Action</th> -->
                        </tr>

                        <tbody>
                          <tr v-for="(beneficiaryaccount, index) in beneficiaryaccounts" :key="index">
                            <td>
                              <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                            </td>

                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_holder_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_number }}
                            </td>

                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.ifsc_code }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_type }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.bank_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.mobile }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              <p v-if="beneficiaryaccount.status == 'pending'" class="text-white btn btn-sm" style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                              <p v-else class="text-white btn btn-sm" style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                            </td>
                            <!-- <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  @click="editBeneficiaryAcount(beneficiaryaccount)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5 class="modal-title" id="exampleModalLabel" style="color: #00364f; font-size: 1.4vw">
            <b>Beneficiary Account </b>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="
            edit == 'false' ? beneficiaryaccountAdd() : updatebeneficiaryAcount()
            ">
            <div class="row">
              <div class="col-12" v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account Holder Name <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="text" class="form-control" v-model="form.account_holder_name" style="height: 30px"
                    placeholder="Account Holder" disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="text" class="form-control" style="height: 30px" v-model="form.account_holder_name"
                    placeholder="Account Holder" @change="benificairyvalidation($event, 'AccountHolderName')" />
                </div>
                <span style="color: #f21000" id="AccountHolderName"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.account_holder_name">{{
                        form.errors.error.account_holder_name[0]
                      }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account Number <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="number" v-model="form.account_number" class="form-control" style="height: 30px"
                    placeholder="Account Number" disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="number" v-model="form.account_number" class="form-control" style="height: 30px"
                    placeholder="Account Number" @change="benificairyvalidation($event, 'AccountNumber')" />
                </div>
                <span style="color: #f21000" id="AccountNumber"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.account_number">{{
                        form.errors.error.account_number[0]
                      }}</span></span></small>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>IFSC <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="text" v-model="form.ifsc_code" class="form-control" style="height: 30px"
                    placeholder="IFSC " disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="text" v-model="form.ifsc_code" class="form-control" style="height: 30px"
                    placeholder="IFSC " @change="benificairyvalidation($event, 'ifscNum')" />
                </div>
                <span style="color: #f21000" id="ifscNum"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.ifsc_code">{{
                        form.errors.error.ifsc_code[0]
                      }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Bank Name <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="text" v-model="form.bank_name" class="form-control" style="height: 30px"
                    placeholder="Bank Name" disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="text" v-model="form.bank_name" class="form-control" style="height: 30px"
                    placeholder="Bank Name" @change="benificairyvalidation($event, 'BankName')" />
                </div>
                <span style="color: #f21000" id="BankName"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.bank_name">{{
                        form.errors.error.bank_name[0]
                      }}</span></span></small>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Phone Number <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="number" v-model="form.mobile" class="form-control" placeholder=" Mobile Number"
                    style="height: 30px" disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="number" v-model="form.mobile" class="form-control" style="height: 30px"
                    placeholder=" Mobile Number" @change="benificairyvalidation($event, 'mobileNo')" />
                </div>
                <span style="color: #f21000" id="mobileNo"></span>

                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.mobile">{{
                        form.errors.error.mobile[0]
                      }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account type <span class="text-danger">*</span></label>
                <div class="from-group">
                  <select class="form-select" v-model="form.account_type" aria-label="Default select example"
                    placeholder="Status" style="height: 30px; padding: 5px 5px 5px 5px">
                    <option value="savings" selected>Savings </option>
                    <option value="superAdminBeneficiaryAccount">Current </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                " data-bs-dismiss="modal">
                <b>Cancel</b>
              </button>
              <button type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!--start wallet withdrawles model  -->
  <div class="modal fade" id="walletWithDrawModel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5 class="modal-title" id="exampleModalLabel" style="color: #00364f; font-size: 1.4vw">
            <b>Withdrawal </b>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="
            withdrawalEdit == 'false'
              ? superadminWalletWithdrawal()
              : updatebeneficiaryAcount()
            ">
            <div class="row">
              <div class="col-12" v-if="walletwithdrawalForm.errors">
                <div v-if="walletwithdrawalForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletwithdrawalForm.errors.message }}
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Amount <span style="color: #f21000">*</span></label>

                <div class="from-group">
                  <input required type="number" min="1" :max="wallet.balanceAmount" v-model="walletwithdrawalForm.amount" class="form-control"
                    style="height: 30px" placeholder="Amount" @change="amtCheck" />
                  <!-- <small id="amountNo" style="color: #f21000"></small> -->
                </div>

                <small style="color: red" v-if="walletwithdrawalForm.errors"><span
                    v-if="walletwithdrawalForm.errors.error"><span v-if="walletwithdrawalForm.errors.error.amount">{{
                      walletwithdrawalForm.errors.error.amount[0]
                    }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Payment Mode <span style="color: #f21000">*</span></label>
                <select required @change="paymantModeValueAssign()" style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.paymentMode" class="form-select" aria-label="Default select example">
                  <option value="upi">UPI</option>
                  <option value="imps">IMPS</option>
                  <option value="neft">NEFT</option>
                  <option value="rtgs" disabled>RTGS</option>
                </select>
                <small style="color: red" v-if="walletwithdrawalForm.errors"><span
                    v-if="walletwithdrawalForm.errors.error"><span v-if="walletwithdrawalForm.errors.error.paymentMode">{{
                      walletwithdrawalForm.errors.error.paymentMode[0]
                    }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode != 'upi'">
                <label>Beneficiaryaccount Account <span style="color: #f21000">*</span></label>
                <select @change="valueTakeInform()" style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.beneficiaryaccount_id" class="form-select"
                  aria-label="Default select example">
                  <option v-for="(beneficiaryaccount, index) in beneficiaryaccounts" :key="index"
                    :value="beneficiaryaccount.id">
                    {{ beneficiaryaccount.account_number }}
                  </option>
                </select>
                <small style="color: red" v-if="walletwithdrawalForm.errors"><span
                    v-if="walletwithdrawalForm.errors.error"><span
                      v-if="walletwithdrawalForm.errors.error.account_number">{{
                        walletwithdrawalForm.errors.error.account_number[0]
                      }}</span></span></small>
              </div>
              <!-- <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode != 'upi'"
              >
                <label>Payment Mode</label>
                <select
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.beneficiaryaccount_type"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="current">Current Acount</option>
                  <option value="savings">Savings Acount</option>
                </select>
              </div> -->
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode == 'upi'">
                <label>UPI <span style="color: #f21000">*</span></label>

                <div class="from-group">
                  <input type="text" v-model="walletwithdrawalForm.merchant_vpa" class="form-control" style="height: 30px"
                    placeholder="UPI Number" />
                </div>
                <small style="color: red" v-if="walletwithdrawalForm.errors"><span
                    v-if="walletwithdrawalForm.errors.error"><span
                      v-if="walletwithdrawalForm.errors.error.merchant_vpa">{{
                        walletwithdrawalForm.errors.error.merchant_vpa[0]
                      }}</span></span></small>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                " data-bs-dismiss="modal">
                <b>Cancel</b>
              </button>
              <button type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end wallet withdrawles   modal -->

  <!--start model  -->
  <div class="modal fade" id="walletModel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5 class="modal-title" id="exampleModalLabel" style="color: #00364f; font-size: 1.4vw">
            <b>Wallet </b>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="
          walletEdit == 'false' ? superadminWalletCreate() : updatebeneficiaryAcount()
            ">
            <div class="row">
              <div class="col-12" v-if="walletForm.errors">
                <div v-if="walletForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletForm.errors.message }}
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Mobile Number <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input required type="number" v-model="walletForm.mobile" class="form-control" style="height: 30px"
                    placeholder="Mobile Number" />
                </div>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.mobile">{{
                      walletForm.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>UPI ID <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input required type="text" v-model="walletForm.merchant_vpa" class="form-control" style="height: 30px"
                    placeholder="UPI ID" />
                </div>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.merchant_vpa">{{
                      walletForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                " data-bs-dismiss="modal">
                <b>Cancel</b>
              </button>
              <button type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!-- Modal Profile Upadate  -->
  <div class="modal fade" id="profileUpdateModel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Profile Update</b></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="msgImgError">

            <div class="alert alert-danger p-1" role="alert">
 {{msgImgError}}
</div>
          </div>  
          <form @submit.prevent="profileUpdate()">
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12" style="padding: 5px 5px 5px 5px">
                <div>
                  <img style="width: 100px; height: 100px" :src="proUpdatePhoto" alt="" />
                </div>
                <label>Photo</label>
                <input @change="uploadPhoto" id="profilePhoto" type="file" class="form-control" placeholder="Name" />
                
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Name</label>
                <input v-model="updateProfileForm.name" type="text" class="form-control" placeholder="Name"
                  @change="lenthheker($event, 'professionalName')" />
                <span style="color: #f21000" id="professionalName"></span>
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Email</label>
                <input type="email" v-model="updateProfileForm.email" class="form-control" placeholder="Email" />
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Phone</label>
                <input type="number" v-model="updateProfileForm.phone" class="form-control" placeholder="Phone"
                  @change="lenthheker($event, 'phonePro')" />
                <span style="color: #f21000" id="phonePro"></span>

                <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.phone">{{
                            updateProfileForm.errors.error.phone[0]
                          }}</span></span
                        ></small
                      >

              </div>
              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Alt Phone</label>

                <input type="number" v-model="updateProfileForm.alternativePhone" class="form-control"
                  placeholder="Alt Phone" @change="lenthheker($event, 'altphonePro')" />
                <span style="color: #f21000" id="altphonePro"></span>
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>DOB</label>
                <input type="date" v-model="updateProfileForm.dob" class="form-control" placeholder="dob" />

                <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.dob">{{
                            updateProfileForm.errors.error.dob[0]
                          }}</span></span
                        ></small
                      >

              </div>
              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Gender</label>
                <select v-model="updateProfileForm.gender" class="form-select" aria-label="Default select example">
                  <option selected disabled>select Gender...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>State</label>
                <select v-model="updateProfileForm.state_id" class="form-select" aria-label="Default select example">
                  <option selected disabled>select...</option>
                  <option :value="sts.id" v-for="(sts, index) in states" :key="index">
                    {{ sts.name }}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>City</label>
                <input v-model="updateProfileForm.city" type="text" class="form-control" placeholder="City"
                  @change="lenthheker($event, 'profesionalCity')" />

                <span style="color: #f21000" id="profesionalCity"></span>
              </div>

              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>Pin</label>

                <input type="number" v-model="updateProfileForm.pin" class="form-control" placeholder="Pin"
                
                  @change="lenthheker($event, 'pinCode')" />
                
                <span style="color: #f21000" id="pinCode"></span>
                
                <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.pin">{{
                            updateProfileForm.errors.error.pin[0]
                          }}</span></span
                        ></small>
              </div>
              <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                <label>GST No.</label>

                <input type="tel" v-model="updateProfileForm.gstNo" class="form-control" placeholder="gstNo"
                  @change="lenthheker($event, 'gstinVal')" />
                  
                <span style="color: #f21000" id="gstinVal"></span>
                <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.gstNo">{{
                            updateProfileForm.errors.error.gstNo[0]
                          }}</span></span
                        ></small>
              </div>
              <div class="col-12 col-md-12 col-xl-12" style="padding: 5px 5px 5px 5px">
                <label>Password</label>

                <input type="text" v-model="updateProfileForm.password" class="form-control" placeholder="password" />
                <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.password">{{
                            updateProfileForm.errors.error.password[0]
                          }}</span></span
                        ></small>
              </div>
              <div class="col-12 col-md-12 col-xl-12" style="padding: 5px 5px 5px 5px">
                <label>Address</label>
                <textarea @change="lenthheker($event, 'profesionalAddress')" class="form-control" rows="3"
                  v-model="updateProfileForm.address" placeholder="Address"></textarea>
                <span style="color: #f21000" id="profesionalAddress"></span>
                
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" data-bs-dismiss="modal"
                style="background-color: #f21000">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminProfile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      profileImg: this.$store.state.placeholderImg,
      superadmin: {},
      withdrawalEdit: "false",
      walletEdit: "false",
      services: [],
      wallet: {},
      walletwithdrawalForm: {
        wallet_id: null,
        amount: null,
        paymentMode: "",
        beneficiaryaccount_id: null,
        beneficiaryaccount_type: "",
        merchant_vpa: "",
        remark: "",
        errors: {},
      },
      beneficiaryaccounts: [],

      walletForm: {
        merchant_vpa: "",
        mobile: "",
        errors: {},
      },
      form: {
        account_number: "",
        account_type: "superAdminBeneficiaryAccount",
        account_holder_name: "",
        ifsc_code: "",
        bank_name: "",
        bank_branch: "",
        mobile: "",
        status: "",
        remark: "",
        ipAddress: "",
        errors: {},
      },
      edit: "false",
      beneficiaryId: null,
      walletwithdrawalsReq: [],
      // validation
      loading: false,
      AccountHolderName: "false",
      AccountNumber: "false",
      ifscNum: "false",
      mobileNo: "false",
      BankName: "false",

      states: [],
      extensionkycProfilePhoto: "",

      proUpdatePhoto: this.$store.state.placeholderImg,
      proUpdatelogo: this.$store.state.placeholderImg,
      updateProfileForm: {
        address: "",
        gstNo: "",
        alternativePhone: "",
        city: "",
        dob: "",
        email: "",
        gender: "",
        ipAddress: "",
        logo: "",
        photo: "",
        name: "",
        phone: "",
        state_id: null,
        password: "",
        pin: "",
        errors:{}
      },
      msgImgError: "",
      phonePro: "false",
      altphonePro: "false",
      professionalName: "false",
      profesionalAddress: "false",
      profesionalCity: "false",
      pinCode: "false",
      gstinVal: "false",
    };
  },
  methods: {

    valueNullProfile() {
      this.msgImgError=""
      this. updateProfileForm={}
      this. updateProfileForm.errors={}
      $("#professionalName").html("<small></small>");
      $("#phonePro").html("<small></small>");
      $("#altphonePro").html("<small></small>");
      $("#profesionalAddress").html("<small></small>");
      $("#profesionalCity").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#gstinVal").html("<small></small>");
    },
    lenthheker(event, type) {
      var data = event.target.value;

      if (type == "professionalName") {
        if (data.length < 3) {
          this.professionalName = "true";
          $("#professionalName").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.professionalName = "false";
          $("#professionalName").html("<small></small>");
        }
      }
      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altphonePro") {
        if (data.length != 10) {
          this.altphonePro = "true";
          $("#altphonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.altphonePro = "false";
          $("#altphonePro").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "profesionalCity") {
        if (data.length < 3) {
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }


      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      // if (type == "proPassword") {
      //   if (data.length < 6) {
      //     this.proPassword = "true";
      //     $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");
      //   }
      //   if (data.length >= 6) {
      //     this.proPassword = "false";
      //     $("#proPassword").html("<small></small>");
      //   }
      // }

      if (type == "gstinVal") {
        if (data.length != 15) {
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }
    },

    valueAssingForProfile() {
      this.valueNullProfile()
      this.updateProfileForm.name = this.superadmin.name;
      this.updateProfileForm.phone = this.superadmin.phone;
      this.updateProfileForm.address = this.superadmin.address;
      this.updateProfileForm.alternativePhone = this.superadmin.alternativePhone;
      this.updateProfileForm.city = this.superadmin.city;
      this.updateProfileForm.email = this.superadmin.email;
      this.updateProfileForm.gender = this.superadmin.gender;
      this.updateProfileForm.state_id = this.superadmin.state_id;
      this.updateProfileForm.pin = this.superadmin.pin;
      this.updateProfileForm.dob = this.superadmin.dob;
      this.updateProfileForm.gstNo = this.superadmin.gstNo;
      if (this.superadmin.logo) {
        this.proUpdatelogo =
          this.$store.state.imgUrl +
          "/superadmin/" +
          this.superadmin.id +
          "/thumbs/" +
          this.superadmin.logo;
      } else {
        this.proUpdatelogo = this.$store.state.placeholderImg;
      }

      if (this.superadmin.photo) {
        this.proUpdatePhoto =
          this.$store.state.imgUrl +
          "/superadmin/" +
          this.superadmin.id +
          "/thumbs/" +
          this.superadmin.photo;
      } else {
        this.proUpdatePhoto = this.$store.state.placeholderImg;
      }
    },
    uploadPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Photo Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profilePhoto").value;
        this.extensionkycProfilePhoto = fileName.split(".").pop();

        if (
          this.extensionkycProfilePhoto == "jpg" ||
          this.extensionkycProfilePhoto == "jpeg" ||
          this.extensionkycProfilePhoto == "png"||
          this.extensionkycProfilePhoto == "JPG" ||
          this.extensionkycProfilePhoto == "JPEG" ||
          this.extensionkycProfilePhoto == "PNG"
        ) {
          this.proUpdatePhoto = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG  ";
        }
      }
    },

    formNullWallet(){
      this.walletForm = {}
      this.walletForm.errors = {}
    },
    loadState() {
      this.$axios
        .get("superadmin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data)
          this.states = res.data.data;
          // console.log(this.states)
        });
    },
    profileUpdate() {

      if (this.phonePro == 'false' && this.altphonePro == 'false' && this.professionalName == 'false' && this.profesionalAddress == 'false' && this.profesionalCity == 'false' && this.pinCode == 'false' && this.gstinVal == 'false') {
        const formData = new FormData();
        const keys = Object.keys(this.updateProfileForm);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.updateProfileForm[keys[n]] !== null) {
            formData.append(keys[n], this.updateProfileForm[keys[n]]);
          }
        }

        this.loading = true;
        this.$axios
          .post(`superadmin/superadmin`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Updated Succesfully", {
              autoClose: 1000,
            });
            $("#profileUpdateModel").modal("hide");
            this.updateProfileForm = {};

            this.loadSuperAdmin();
            window.location.reload();
          })
          .finally(() => (this.loading = false))

          .catch((error) => {

            this.updateProfileForm.errors=error.response.data
// this.$swal.fire({
//       position: "top-center",
//       icon: "error",
//       title: "<small>" + error.response.data.error + "</small>",
//       showConfirmButton: true,
//       confirmButtonColor: "#00364f",
//     });



  });

      } else {
        this.$swal.fire({
          position: "top-center",
          icon: "error",

          title: "<small>Please Check The Provided Information Corect Or Not  ?</small>",
          showConfirmButton: true,

        });
      }





    },

    // profile update area end
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    walletWithDrawModelNull(){
      this.walletwithdrawalForm = {}
      this.walletwithdrawalForm.errors = {}
    },
    walletWithdrawReqApproved(value) {
      console.log(value);
      var form = {};

      if (value.paymentMode == "upi") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          merchant_vpa: value.merchant_vpa,
          beneAccNo: value.merchant_vpa,
          beneIFSC: value.merchant_vpa,
          beneName: value.merchant_vpa,
        };
      }
      if (value.paymentMode == "imps" || value.paymentMode == "neft") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }
      if (value.paymentMode == "rtgs") {
        form = {
          paymentMode: value.paymentMode,
          amount: value.amount,
          beneAccNo: value.beneficiaryaccount.account_number,
          beneIFSC: value.beneficiaryaccount.ifsc_code,
          beneName: value.beneficiaryaccount.account_holder_name,
        };
      }

      console.log(form);
      this.loading = true;
      this.$axios
        .post(`superadmin/walletwithdrawal/approve/${value.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Request Approved  successfully", {
            autoClose: 1000,
          });

          this.superadminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false));
    },
    amtCheck(e) {
      $("#amountNo").html("<small></small>");
      var amt = e.target.value;
      if (amt == 0) {
        $("#amountNo").html("<small>please enter minimun 1</small>");
      }
    },
    valueTakeInform() {
      console.log(this.walletwithdrawalForm.beneficiaryaccount_id);
      console.log(this.walletwithdrawalForm);

      var res = this.beneficiaryaccounts.find(
        (p) => p.id == this.walletwithdrawalForm.beneficiaryaccount_id
      );
      console.log(res);
    },
    paymantModeValueAssign() {
      $("#amountNo").html("<small></small>");
      if (this.walletwithdrawalForm.paymentMode == "upi") {
        if (this.walletwithdrawalForm.amount == 0) {
          $("#amountNo").html("<small>please enter minimun 1</small>");
        }
        this.walletwithdrawalForm.merchant_vpa = this.wallet.merchant_vpa;
      } else {
        this.walletwithdrawalForm.merchant_vpa = "";
      }

      if (
        this.walletwithdrawalForm.paymentMode == "imps" ||
        this.walletwithdrawalForm.paymentMode == "neft"
      ) {
        if (this.walletwithdrawalForm.amount == 0) {
          $("#amountNo").html("<small>please enter minimun 1</small>");
        }
      }

      if (this.walletwithdrawalForm.paymentMode == "rtgs") {
        if (this.walletwithdrawalForm.amount < 200000) {
          $("#amountNo").html("<small>RTGS Mode Enter minimum 200000 </small>");
        } else {
          $("#amountNo").html("<small></small>");
        }
        // this.walletwithdrawalForm.merchant_vpa = this.wallet.merchant_vpa;
      } else {
        // this.walletwithdrawalForm.merchant_vpa = "";
      }
    },
    superadminWalletWithdrawalGet() {
      // this.walletwithdrawalForm.wallet_id = this.wallet.id;
      this.loading = true;
      this.$axios
        .get("superadmin/walletwithdrawal?user_type=superAdmin&per_page=5", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("Req =====");
          console.log(res.data.data.data);
          console.log("Req =====");

          this.walletwithdrawalsReq = res.data.data.data;
        })
        .catch((error) => {
          this.walletwithdrawalForm.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
    },
    superadminWalletWithdrawal() {
      this.walletwithdrawalForm.wallet_id = this.wallet.id;
      if (this.walletwithdrawalForm.paymentMode != "upi") {
        this.walletwithdrawalForm.beneficiaryaccount_type =
          "superAdminBeneficiaryAccount";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/walletwithdrawal", this.walletwithdrawalForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Request Sent successfully", {
            autoClose: 1000,
          });

          this.walletwithdrawalForm = {};
          $("#walletWithDrawModel").modal("hide");

          this.superadminWalletWithdrawalGet();
        })
        .catch((error) => {
          this.walletwithdrawalForm.errors = error.response.data;
        })
        .finally(() => (this.loading = false));

      this.superadminWallet();
    },

    walletWithdrawReqCancel(value) {
      this.loading = true;
      this.$axios
        .post(
          `superadmin/walletwithdrawal/reject/${value.id}`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success("Request Canceled  successfully", {
            autoClose: 1000,
          });

          this.superadminWalletWithdrawalGet();
        })

        .finally(() => (this.loading = false));
    },
    benificairyvalidation(event, type) {
      var data = event.target.value;
      if (type == "AccountHolderName") {
        if (data.length < 3) {
          this.AccountHolderName = "true";
          $("#AccountHolderName").html(
            "<small>Account Holder required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.AccountHolderName = "false";
          $("#AccountHolderName").html("<small></small>");
        }
      }

      if (type == "BankName") {
        if (data.length < 3) {
          this.BankName = "true";
          $("#BankName").html("<small>Bank Name  required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.BankName = "false";
          $("#BankName").html("<small></small>");
        }
      }

      if (type == "AccountNumber") {
        if (data.length < 9) {
          this.AccountNumber = "true";
          $("#AccountNumber").html(
            "<small>Account Number Required Between 9-18 Digits</small>"
          );
        }
        if (data.length >= 9) {
          this.AccountNumber = "false";
          $("#AccountNumber").html("<small></small>");
        }
      }

      if (type == "ifscNum") {
        if (data.length != 11) {
          this.ifscNum = "true";
          $("#ifscNum").html("<small>IFSC Required  11 Digits</small>");
        }
        if (data.length == 11) {
          this.ifscNum = "false";
          $("#ifscNum").html("<small></small>");
        }
      }

      if (type == "mobileNo") {
        if (data.length != 10) {
          this.mobileNo = "true";
          $("#mobileNo").html("<small>Mobile No. Required  10 Digits</small>");
        }
        if (data.length == 10) {
          this.mobileNo = "false";
          $("#mobileNo").html("<small></small>");
        }
      }
    },
    benificiaryNull() {
      this.form = {};
      this.edit = "false";
      $("#AccountHolderName").html("<small></small>");
      $("#BankName").html("<small></small>");
      $("#mobileNo").html("<small></small>");
      $("#ifscNum").html("<small></small>");
      $("#AccountNumber").html("<small></small>");
    },
    editBeneficiaryAcount(acount) {
      this.form_id = acount.id;
      this.beneficiaryId = acount.id;

      this.form = acount;
      this.edit = "true";
    },
    updatebeneficiaryAcount() {
      if (
        this.BankName == "false" &&
        this.mobileNo == "false" &&
        this.ifscNum == "false" &&
        this.AccountNumber == "false" &&
        this.AccountNumber == "false"
      ) {
        // start
        this.loading = true;
        this.$axios
          .put(`superadmin/beneficiaryaccount/${this.beneficiaryId}`, this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("Beneficiary Account Updated successfully", {
              autoClose: 1000,
            });
            this.form = {};
            $("#exampleModal").modal("hide");
            this.superadminBeneficiaryaccount();

            this.form = {};
            this.form_id = null;
            this.beneficiary_id = null;
            this.edit = "false";
            this.loadBeneficiaryAccount();
          })
          .finally(() => (this.loading = false));
        // end
      } else {
        toast.warning("Please Enter Valid Details", {
          autoClose: 1000,
        });
      }
    },

    beneficiaryaccountAdd() {
      if (
        this.BankName == "false" &&
        this.mobileNo == "false" &&
        this.ifscNum == "false" &&
        this.AccountNumber == "false" &&
        this.AccountNumber == "false"
      ) {
        // start
        this.loading = true;
        this.$axios
          .post("superadmin/beneficiaryaccount", this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data);

            toast.success("Beneficiary Account Created successfully", {
              autoClose: 1000,
            });
            this.form = {};
            $("#exampleModal").modal("hide");
            this.superadminBeneficiaryaccount();
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
        // end
      } else {
        toast.warning("Please Enter Valid Details", {
          autoClose: 1000,
        });
      }
    },
    superadminWallet() {
      this.loading = true;
      this.$axios
        .get("superadmin/wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.wallet = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    superadminWalletCreate() {
      this.loading = true;
      this.$axios
        .post("superadmin/wallet", this.walletForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Wallet Created Succesfully", {
            autoClose: 1000,
          });
          $("#walletModel").modal("hide");
          this.superadminWallet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.walletForm.errors = error.response.data;
        })
    },
    superadminBeneficiaryaccount() {
      this.loading = true;
      this.$axios
        .get("superadmin/beneficiaryaccount", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadSuperAdmin() {
      this.loading = true;
      this.$axios
        .post(
          "superadmin/user",
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(res.data);
          this.superadmin = res.data;
          if (this.superadmin.photo) {
            this.profileImg =
              this.$store.state.imgUrl +
              "/superadmin/" +
              this.superadmin.id +
              "/thumbs/" +
              this.superadmin.photo;
          } else {
            this.profileImg = this.$store.state.placeholderImg;
          }
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.profile
    console.log(this.$store.state.superAdminPageTitles.profile)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadSuperAdmin();
      this.loadState();
      this.superadminWallet();
      this.superadminWalletWithdrawalGet();
      this.superadminBeneficiaryaccount();
      
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
